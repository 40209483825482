import React from "react";
import {
  ConstrainedPageStyle,
  PageTitle,
  Section,
  FloatImageMax,
  P,
  Ol,
  ListItem,
  FloatImageWrapperWithCredit,
} from "../styles/page";
import { BoxoutContactStyle } from "../styles/hero";
import { Link, useLocation } from "react-router-dom";
import { FaqItemWrapStyle } from "../styles/faq";
import { FaqItem } from "../components/faq";
import { IFaqItem } from "../components/faq/lib";
import { AExternal, DownloadLink } from "../components/Links";

const entries: IFaqItem[] = [
  {
    uid: "samhe-methods-paper-nov-23",
    label: "SAMHE Methods paper, November 2023",
    group: null,
    audience: [],
    content: (
      <div>
        <FloatImageMax
          src="/images/resources/outputs/Methods_paper_image_for_PFW.jpg"
          alt="Three school children in a classroom looking at digital tablets"
        />
        <P>
          The SAMHE (Schools' Air quality Monitoring for Health and Education) project is the first stage of the SAMHE
          initiative. It introduces a new way to generate and analyse large datasets about air quality and school
          classrooms via a collaborative citizen science method. This method was chosen because it allows us to collect
          information about air quality whilst raising awareness about the topic amongst pupils, teachers and other
          staff.
        </P>
        <P>
          SAMHE is important because children in the UK spend a large proportion of their days inside school classrooms,
          and the project will help us understand the quality of the air in those classrooms. The paper talks about why
          good indoor air quality is important, and what is known so far. The paper describes how we plan to give 2000
          low-cost air quality monitors to classrooms and provide a Web App to pupils and staff through which they can
          submit contextual data (school buildings characteristics and operation, behavioural patterns etc), interact
          with the data gathered in their school and use it in specially created learning activities.
        </P>
        <P>
          SAMHE has proved popular with schools, showing that this new method could be valuable more widely for
          gathering indoor air quality datasets while simultaneously enabling schools to better manage their indoor
          environment and empowering students and teachers to reduce their environmental health risks.
        </P>
        <P>
          Read the paper:{" "}
          <AExternal href="https://doi.org/10.1016/j.dibe.2023.100266">
            Schools' air quality monitoring for health and education: Methods and protocols of the SAMHE initiative and
            project
          </AExternal>
        </P>
        <P>
          <AExternal href="https://www.youtube.com/watch?v=vraKJUbT_xY">Watch a short video</AExternal> designed for
          pupils in which Sarah West introduces this paper and our co-design paper and explains why we wrote them and
          what we learned through the process of co-design.
        </P>
      </div>
    ),
  },
  {
    uid: "samhe-codesign-paper-nov-23",
    label: "SAMHE Co-design paper, November 2023",
    group: null,
    audience: [],
    content: (
      <div>
        <FloatImageMax
          src="/images/resources/outputs/Co-design_paper_image_for_PFW.jpg"
          alt="Primary school children in a classroom holding up pieces of paper with green ticks or red crosses"
        />
        <P>
          Central to the SAMHE project is the Web App, where teachers and pupils can see air quality data from their
          classroom, learn about its significance, enter important contextual information to support data analysis by
          researchers, and do experiments.
        </P>
        <P>
          School use of the Web App is essential to the project's aims to
          <Ol>
            <ListItem> improve understanding of air quality in schools; </ListItem>
            <ListItem>
              empower teachers and pupils to make informed decisions about managing their classroom environment,
              including ventilation; and
            </ListItem>
            <ListItem>support the next generation to think differently about IAQ.</ListItem>
          </Ol>
        </P>
        <P>
          Therefore, it was critical that the Web App was co-designed with schools, to maximise its acceptability and
          ensure that teachers and pupils engage with it.
        </P>
        <P>
          Pupils participated in co-design workshops with a teacher, either as a class group or as a lunchtime or
          after-school extra-curricular group such as a science club, school council or eco-group. Arkwright Scholars,
          aged 16+, participated without teachers. Teachers also participated, without students, in small group
          discussions and could input via Padlet boards at times that suited them. Schools seemed to enjoy the
          interactions with researchers, including chats about our careers, and their input has created a co-designed
          Web App which they enjoy using and gives researchers important contextual data.
        </P>
        <P>
          Read the paper:{" "}
          <AExternal href="https://theoryandpractice.citizenscienceassociation.org/articles/10.5334/cstp.620">
            'Co-Designing an Air Quality Web App with School Pupils and Staff: The SAMHE Web App'
          </AExternal>
        </P>
        <P>
          <AExternal href="https://www.youtube.com/watch?v=vraKJUbT_xY">Watch a short video</AExternal> designed for
          pupils in which Sarah West introduces this paper and our methods paper and explains why we wrote them and what
          we learned through the process of co-design.
        </P>
      </div>
    ),
  },
  {
    uid: "samhe-tech-report-january-2024",
    label: "SAMHE tech report on Saharan dust event in September 2023, January 2024",
    group: null,
    audience: [],
    content: (
      <div>
        <FloatImageMax
          src="/images/resources/outputs/PM_map.png"
          alt="Three maps of the UK showing AURN site PM2.5 readings as coloured dots. In the first image, the PMs are highest around London and the north of England. On the second, the high PMs are more evenly distributed throughout the country. In the third, much lower PM levels can be seen all around the country."
        />
        <P>
          In September 2023, dust from the Sahara desert, in Africa, was carried by a storm to the UK. We looked at air
          quality data in classrooms, measured using SAMHE monitors, to see if this affected the amount of particulate
          matter (PM) in classrooms. To support our investigation, we also looked at air quality measurements made
          outdoors (by the Automatic Urban and Rural Network, AURN).
        </P>
        <P>
          During the period that Sahara dust was reported to be over the UK (based on satellite and weather data) the PM
          levels recorded were significantly higher both indoors and outdoors. Also, the regional variation in outdoor
          PM measurements increased during this period, suggesting that some regions of the UK were more strongly
          affected than others.
        </P>
        <P>
          Interestingly, although almost all schools with SAMHE monitors showed increased PM levels during this dust
          event, they differed widely in how much this increase reflected the change in the outdoor PM measurements. If
          we can discover what factors caused some schools to be more affected by the increased outdoor PMs than others,
          this could help develop practices to improve air quality indoors in general, perhaps even in environments
          where the outdoor air quality is poor.
        </P>
        <P>
          Read the tech report:{" "}
          <DownloadLink href="/resources/SAMHE_Tech_report_Saharan_dust_event.pdf">
            Evidence of a Saharan dust event from air quality measurements in UK schools within the SAMHE project
          </DownloadLink>
        </P>
      </div>
    ),
  },
  {
    uid: "samhe-paper-on-measuring-ventilation-rates-april-2024",
    label: "SAMHE paper on how we can work out ventilation rates, April 2024",
    group: null,
    audience: [],
    content: (
      <div>
        <FloatImageMax
          src="/images/resources/outputs/school-classroom.jpg"
          alt="School classroom with some windows open."
        />
        <P>
          How can we measure the amount of fresh air entering a room? We need to know this 'ventilation rate' to
          understand how air pollution gets in or out. However, it's hard to measure because air can enter and leave a
          room by a variety of routes: not just through open windows and doors but also through tiny gaps in the
          material of the walls, floor and ceiling.
        </P>
        <P>
          We looked at school classrooms and explored how we can use simple measurements of carbon dioxide (CO
          <sub>2</sub>) to predict the ventilation rate. These two things are connected because people in a room add CO
          <sub>2</sub> to the air by breathing and it is removed by ventilation.
        </P>
        <P>
          There are two complicating factors: classrooms can vary significantly between schools, or even within the same
          school (e.g. by classroom size and pupil numbers and ages), and the ventilation rate can change throughout the
          day (e.g. as a result of changing winds or windows being opened or closed).
        </P>
        <P>
          Despite this variability, we show that it is possible to work out daily average ventilation rates (per person)
          from the CO<sub>2</sub> levels measured in classrooms to an accuracy of ±24%. We suggest ways to improve the
          accuracy (to around ±12%) including by using multiple CO<sub>2</sub> sensors in one classroom. We hope these
          findings will improve scientists' understanding of factors that affect indoor air quality in schools, leading
          to better management in the future.
        </P>
        <P>
          Read the paper:{" "}
          <AExternal href="https://doi.org/10.1016/j.buildenv.2024.111309">
            'Inferring ventilation rates with quantified uncertainty in operational rooms using point measurements of
            carbon dioxide: Classrooms as a case study'
          </AExternal>
        </P>
      </div>
    ),
  },
  {
    uid: "samhe-tech-report-on-the-impact-of-fireworks-events-on-classroom-air-quality-april-2024",
    label: "SAMHE tech report on the impact of fireworks events on classroom air quality, April 2024",
    group: null,
    audience: [],
    content: (
      <div>
        <FloatImageMax
          src="/images/resources/outputs/fireworks.jpg"
          alt="red and yellow fireworks exploding against a black sky."
        />
        <P>
          In the UK, during autumn, fireworks are used in the celebration of Fireworks Night (or Guy Fawkes Night) and
          Diwali. Setting off fireworks releases pollutants, including particulate matter (PM). We investigated whether
          these fireworks events could be detected by SAMHE monitors in UK schools. We compared the indoor concentration
          of PM<sub>2.5</sub> (particulate matter smaller than 2.5 microns), as recorded by SAMHE monitors, to the
          outdoor concentration of PM<sub>2.5</sub>, as recorded by Defra's AURN (Department for Environment, Food &
          Rural Affairs Automatic Urban and Rural Network) weather stations, during autumn 2023.
        </P>
        <P>
          As the concentration of PM<sub>2.5</sub> increased outdoors after people set off fireworks, the concentration
          measured by SAMHE monitors also increased. Most of the fireworks events occurred over the weekends and during
          the evenings, when we expect most schools to be closed and have lower ventilation levels than during school
          hours. Even so, the concentration of PM<sub>2.5</sub> indoors increased, showing that PM<sub>2.5</sub> can get
          into schools even when they are closed.
        </P>
        <P>
          Levels of PM<sub>2.5</sub> were not significantly increased during school hours in the weeks after each
          fireworks event. Reassuringly, we can conclude that, whilst the PM from fireworks did enter schools, it did
          not have a large effect on air quality during school hours.
        </P>
        <P>
          Read the tech report:{" "}
          <DownloadLink href="/resources/Fireworks_Events_SAMHE_Tech_Report.pdf">
            The impact of fireworks events on classroom air quality
          </DownloadLink>
        </P>
      </div>
    ),
  },
  {
    uid: "samhe-tech-report-on-initial-findings-from-the-2023-autumn-term-april-2024",
    label: "SAMHE tech report on initial findings from the 2023 Autumn term, April 2024",
    group: null,
    audience: [],
    content: (
      <div>
        <FloatImageMax
          src="/images/resources/outputs/window-frost.jpg"
          alt="View through a frosty window from inside."
        />
        <P>
          We used data from about 300 SAMHE monitors (all those which were submitting data at the time) to investigate
          air quality in UK schools over the 2023 Autumn term. The UK experienced changeable weather in Autumn 2023 with
          unseasonably warm weather at the start of term and a period of far colder weather later on in the term, each
          followed by periods of intermediate temperature.
        </P>
        <P>
          Classroom temperatures were slightly higher during the really warm period but during all other times they
          remained more or less the same, independent of the weather outside. In the cold period, classroom carbon
          dioxide (CO<sub>2</sub>) levels were substantially higher than during the warm period, indicating ventilation
          was likely to have been reduced; in fact, during the cold period, many monitors recorded daily mean CO
          <sub>2</sub> values above the maximum government guideline of 1500 ppm. We all breathe out air which is of
          high humidity and high CO<sub>2</sub> concentration, but the humidity measured by SAMHE monitors did not show
          the same trend as CO<sub>2</sub>. During the cold period, when CO<sub>2</sub> was high, the classroom
          humidities measured were low. We know that outdoor humidity varies significantly with the weather. Typically
          humidity becomes lower (i.e. the air is drier) during colder weather, so our data suggests that classroom
          humidity is directly influenced by the weather, whereas CO<sub>2</sub> is influenced differently.
        </P>
        <P>
          Particulate matter (PM<sub>2.5</sub>) levels varied widely over the course of the term in ways that were not
          dependent on outdoor temperature, suggesting that classroom PM<sub>2.5</sub> concentration depends on a range
          of indoor and outdoor factors. Reassuringly, PM<sub>2.5</sub> levels in SAMHE classrooms generally remained
          below the maximum daily mean value of 15 μ/m<sup>3</sup> recommended by the World Health Organisation.
        </P>
        <P>
          Read the tech report:{" "}
          <DownloadLink href="/resources/SAMHE_Findings_from_Autumn_term_2023_updated.pdf">
            Findings from Autumn term 2023 for SAMHE Champions and other interested schools/parties
          </DownloadLink>
        </P>
      </div>
    ),
  },
  {
    uid: "samhe-tech-report-on-findings-from-the-2024-spring-term-may-2024",
    label: "SAMHE tech report on findings from the 2024 Spring term, May 2024",
    group: null,
    audience: [],
    content: (
      <div>
        <FloatImageMax src="/images/resources/outputs/thermometer.jpg" alt="Thermometer hanging outside" />
        <P>
          We used data from about 350 SAMHE monitors (all those which were submitting data at the time) to investigate
          air quality in UK schools over the 2024 Spring term.
        </P>
        <P>
          Classroom temperatures remained relatively constant throughout the Spring term, independent of the weather
          outside. During the brief cold period at the start of term, classroom carbon dioxide (CO<sub>2</sub>) levels
          were slightly elevated compared to the warmer period that followed, indicating ventilation was likely to have
          been reduced. In fact, during the cold period, many monitors recorded daily mean CO<sub>2</sub> values above
          the maximum government guideline of 1500 ppm.
        </P>
        <P>
          During the cold period, when CO<sub>2</sub> levels were elevated, the measured classroom humidity levels were
          low. We know that outdoor humidity varies significantly with the weather. Typically, humidity levels are lower
          (i.e. the air is drier) during colder weather, so our data suggests that classroom humidity is directly
          influenced by the weather, whereas CO<sub>2</sub> is influenced differently.
        </P>
        <P>
          Particulate matter (PM<sub>2.5</sub>) levels fluctuated over the course of the term but generally remained
          below the maximum daily mean value of 15 μg/m<sup>3</sup> recommended by the World Health Organisation. There
          is no correlation of PM<sub>2.5</sub> concentrations to outdoor temperatures; however, the spikes in PM
          <sub>2.5</sub> recorded from classroom data do correlate with outdoor PM measurements across the country. This
          suggests that the classroom environments are not only affected by conditions local to the school but are also
          influenced by weather and long-range events.
        </P>
        <P>
          Read the tech report:{" "}
          <DownloadLink href="/resources/SAMHE_Spring_Term_2024_Report_updated.pdf">
            Findings from Spring term 2024 for SAMHE Champions and other interested schools/parties
          </DownloadLink>
        </P>
      </div>
    ),
  },
  {
    uid: "samhe-tech-report-on-findings-from-the-20232024-academic-year-august-2024",
    label: "SAMHE tech report on findings from the 2023/2024 academic year, August 2024",
    group: null,
    audience: [],
    content: (
      <div>
        <FloatImageMax
          src="/images/resources/outputs/SAMHE_monitors_map.png"
          alt="Map showing distribution of schools with SAMHE monitors as blue marks on a map of the UK. The monitors can be seen to give good coverage of the southern and middle part of England and the Scottish central belt, with lower density in northern England, Wales, Northern Ireland and SW and the east coast of Scotland, and particularly low densities in mid Wales and the rest of Scotland."
        />
        <P>
          We used data from SAMHE monitors to investigate air quality in UK schools over the 2023/2024 academic year.
        </P>
        <P>
          Classroom temperatures remained relatively constant throughout the year, independent of the weather outside.
          During the cold periods at the end of the Autumn term 2023 and at the start of the Spring term 2024, classroom
          carbon dioxide (CO<sub>2</sub>) levels were slightly elevated compared to the warmer periods seen at the
          beginning and end of the academic year. This indicates that classroom ventilation was likely to have been
          reduced. In fact, during the cold periods, some monitors recorded daily mean CO<sub>2</sub> values above the{" "}
          <AExternal href="https://www.gov.uk/government/publications/building-bulletin-101-ventilation-for-school-buildings">
            maximum government guideline of 1500 ppm
          </AExternal>
          .
        </P>
        <P>
          During the cold periods, when CO<sub>2</sub> levels were elevated, the measured classroom humidity levels were
          low. We know that outdoor humidity varies significantly with the weather. Typically, humidity levels are lower
          (i.e. the air is drier) during colder weather, so our data suggests that classroom humidity is directly
          influenced by the weather, whereas CO<sub>2</sub> is influenced indirectly, via behavioural changes.
        </P>
        <P>
          Particulate matter (PM<sub>2.5</sub>) levels fluctuated over the course of the academic year but generally
          remained below the{" "}
          <AExternal href="https://www.who.int/news-room/fact-sheets/detail/ambient-(outdoor)-air-quality-and-health">
            maximum daily mean value of 15 μg/m<sup>3</sup> recommended by the World Health Organisation
          </AExternal>
          . There is no correlation of PM<sub>2.5</sub> concentrations to outdoor temperatures; however, the rise and
          fall in PM
          <sub>2.5</sub> recorded from classroom data does correlate with outdoor PM measurements across the country.
          This suggests that classroom environments are not only affected by conditions local to the school but are also
          influenced by weather and long-range events.
        </P>
        <P>
          Read the tech report:{" "}
          <DownloadLink href="/resources/SAMHE_Full_Year_Report_23_24.pdf">
            Findings from the academic year 2023/2024 for SAMHE Schools, SAMHE Champions and other interested parties
          </DownloadLink>
        </P>
      </div>
    ),
  },
  {
    uid: "samhe-paper-on-assessing-classroom-ventilation-rates-august-2024",
    label: "SAMHE paper on assessing classroom ventilation rates, August 2024",
    group: null,
    audience: [],
    content: (
      <div>
        <FloatImageWrapperWithCredit desktopStyleOverride={"max-width: 100%"}>
          <img
            src="/images/resources/outputs/classroom_block_at_little_heath_school.jpg"
            alt="A flat-roofed brick school block with many windows, one of which is open, against a grey sky and bare trees."
          />
          <p className="credit">
            <AExternal href="https://creativecommons.org/licenses/by-sa/2.0">
              Classroom block at Little Heath School by Roger Templeman, CC BY-SA 2.0
            </AExternal>{" "}
            (cropped) via Wikimedia Commons
          </p>
        </FloatImageWrapperWithCredit>
        <P>
          We analysed air quality data from 190 SAMHE air quality monitors over the Autumn term 2023 to assess
          ventilation rates in classrooms.{" "}
          <AExternal href="https://www.gov.uk/government/publications/building-bulletin-101-ventilation-for-school-buildings">
            UK government guidance
          </AExternal>{" "}
          states that the daily average concentration of CO
          <sub>2</sub> in occupied classrooms should be kept under 1500 ppm. We found that whether classroom CO
          <sub>2</sub> levels stayed below this threshold or not depended strongly on outdoor temperature. When it's
          colder outside, windows tend to be kept closed more of the time, reducing ventilation. Overall, most schools
          adhered to the guideline level but a number of individual schools regularly exceeded it, often by a
          substantial amount.
        </P>
        <P>
          We also used a novel method to assess the ventilation rate per person in occupied classrooms. Generally this
          was low. Schools in poorer areas were usually more poorly ventilated than those in richer areas, and state
          schools were usually more poorly ventilated than fee-paying schools. Schools with more pupils than their
          target number were generally more poorly ventilated too. Primary schools and secondary schools showed similar
          ventilation rates, but secondary schools generally had higher CO<sub>2</sub> levels, as older children, being
          bigger, breathe out more CO<sub>2</sub>
        </P>
        <P>
          Read the paper:{" "}
          <AExternal href="https://doi.org/10.1016/j.dibe.2024.100520">
            Assessing classroom ventilation rates using CO<sub>2</sub> data from a nationwide study of UK schools and
            identifying school-wide correlation factors
          </AExternal>
        </P>
      </div>
    ),
  },
];

const OutputsPage: React.FC = () => {
  const { hash } = useLocation();
  const uid = hash?.replace("#", "");

  return (
    <ConstrainedPageStyle style={{ marginBottom: "1rem", padding: "1rem" }}>
      <Section>
        <PageTitle>SAMHE Outputs</PageTitle>
        <BoxoutContactStyle style={{ marginBottom: "1rem", padding: "1rem" }}>
          <P>
            This page hosts summaries of all reports and research papers about the SAMHE project that have been
            published by the <Link to="/about/team">SAMHE team</Link> to date, with links to the full versions for those
            interested in reading about our work in more detail.
          </P>
          <P>
            Some of these publications have related activities in the Web App to help pupils understand the findings
            from the project.
          </P>
          <P>
            As further papers and reports using the SAMHE data are published in coming months, they will be added here.
          </P>
        </BoxoutContactStyle>
      </Section>
      <FaqItemWrapStyle>
        {entries.map((item) => (
          <FaqItem key={item.uid} data={item} open={item.uid === uid} />
        ))}
      </FaqItemWrapStyle>
    </ConstrainedPageStyle>
  );
};

export default OutputsPage;
