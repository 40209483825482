import { Link } from "react-router-dom";
import { INewsEntryProps } from "./entry";

export const newsletterEntries: INewsEntryProps[] = [
  {
    title: "SAMHE Newsletter #13",
    date: "September 2024",
    link: "https://094gt.mjt.lu/nl3/mx0heOT_7qVZMjmHpSInLQ?hl=en",
    text: (
      <p>
        Featuring inspiring stories from SAMHE schools, new activities and data visualisations, our latest findings and
        ways we're sharing them with those who can make a difference to air quality in UK schools.
      </p>
    ),
  },
  {
    title: "SAMHE Newsletter SPECIAL ISSUE",
    date: "September 2024",
    link: "https://094gt.mjt.lu/nl3/RkHlJ-soB3zhEgVc061f-Q?hl=en",
    text: (
      <p>
        This special issue is to share the launch of our Teacher Resource Pack for schools without a SAMHE monitor! We
        also share recordings of our webinars on SAMHE's findings and tell you what we're doing next!
      </p>
    ),
  },
  {
    title: "SAMHE Newsletter #11",
    date: "June 2024",
    link: "https://094gt.mjt.lu/nl3/5Hm6XEAvq_Z542fXwXdamQ?hl=en",
    text: (
      <p>
        Featuring new activities and a poster competition for Clean Air Day (20 June) and inviting you to a webinar to
        hear what the SAMHE project has discovered about air quality in UK schools! Plus more research findings,
        additional resources and the chance to use your STEM expertise to support schools.
      </p>
    ),
  },
  {
    title: "SAMHE Newsletter #10",
    date: "April 2024",
    link: "https://094gt.mjt.lu/nl3/whqQKY1EJdE7HVdfdAfbUQ?hl=en",
    text: (
      <p>
        Featuring exciting new activities, support from STEM Ambassadors and how SAMHE can support your school's
        Modeshift STARS work on sustainable travel! Plus important updates on recruitment and support.
      </p>
    ),
  },
  {
    title: "SAMHE Newsletter #9",
    date: "February 2024",
    link: "https://094gt.mjt.lu/nl3/V3pcbWwuW2Kd6sQ0Z3i-VQ?hl=en",
    text: (
      <p>
        Featuring SAMHE activities for British Science Week 8-17 March, including experiments and our exciting new
        careers activity, and introducing new data views, average values for air quality metrics across SAMHE schools
        and more!
      </p>
    ),
  },
  {
    title: "SAMHE Newsletter #8",
    date: "December 2023",
    link: "https://094gt.mjt.lu/nl3/m-r867iEmystXRTRrwNrGw?hl=en",
    text: (
      <p>
        Introducing our end of term quiz, careers resource for schools and the first SAMHE research papers, announcing
        the winner of our November connection competition and explaining how your actions are helping us recruit more
        schools!
      </p>
    ),
  },
  {
    title: "SAMHE Newsletter #7",
    date: "November 2023",
    link: "https://094gt.mjt.lu/nl3/zzSE8Q8GCicF1vC2WgYTaQ?hl=en",
    text: (
      <p>
        Launching our new connection competition - get connected and you could win a prize! Also, discover an exciting
        new Web App activity, improved data views, easier-to-use activity steps and more!
      </p>
    ),
  },
  {
    title: "SAMHE Newsletter #6",
    date: "September 2023",
    link: "https://0okur.mjt.lu/nl3/YUlisQQAwhUhfT7-Jfrklg?hl=en",
    text: (
      <p>
        Discover an exciting new Web App activity, posters to download, an opportunity to get some SAMHE stickers, help
        getting your monitor set up, an opportunity for schools in the Greater Manchester area, and more!
      </p>
    ),
  },
  {
    title: "SAMHE Newsletter #5",
    date: "June 2023",
    link: "https://0okur.mjt.lu/nl3/S-5ZVOFpPJ-2zn1dLYhAWA?hl=en",
    text: (
      <p>
        Discover new activities available in the Web App, find out what the team have been up to and get ideas for
        recruiting another school in your area!
      </p>
    ),
  },
  {
    title: "SAMHE Newsletter #4",
    date: "April 2023",
    link: "https://0okur.mjt.lu/nl3/Gn81G_jZgMcPgBFdBaPs_A?m=AVsAABQ_JdwAAAADUKoAABk0OdcAAAAAwmAAASKoAB2PPwBkSQ69rcolr5YZQlG5eAe3E4FokQAcHRA&b=ede28c54&e=456bd3b2&x=yslqlnYyqB8hD6ehBZm28JdcDClQYtkOCqp1HKwGH9M",
    text: (
      <p>
        Read stories from two of our Pioneer schools, find out what's new on this website and the Web App, and discover
        an opportunity to get involved in additional SAMHE research.
      </p>
    ),
  },
  {
    title: "SAMHE Newsletter #3",
    date: "March 2023",
    link: "https://0okur.mjt.lu/nl3/ao7W8gJhbSuyXrrl35XfKg?m=AV0AABOijb4AAAADUUQAABk0OdcAAAAAwmAAASKoAB2PPwBkSS6C5TQ_AoqkRh68j5e44FxnVwAcHRA&b=2bef267a&e=20b0578d&x=yslqlnYyqB8hD6ehBZm28JdcDClQYtkOCqp1HKwGH9M",
    text: (
      <p>
        Discover the 7 types of activity the Web App will offer, including 'Data Detectives' which Pioneer schools are
        testing this week. See how they have helped us refine the air quality data plots, making them more useable and
        useful for pupil and teachers. Get excited about the in-app achievement pathways and rewards and the upcoming
        SAMHE launch!
      </p>
    ),
  },
  {
    title: "SAMHE Newsletter #2",
    date: "December 2022",
    link: "https://0okur.mjt.lu/nl3/9MzQFa2MUqbZdPmWrs2f6A?m=AVIAAAWA2VEAAAACSpAAAAXQM3MAAAAAwmAAAMvxAB2PPwBjmaGrsHhWiuHoQ5-3FvWGJFme0gAcHRA&b=589476db&e=45efeab1&x=TOtXwFe9u4wE-1fl3ugZ6wxKTLKspYI6Zo-TAJSqnXE",
    text: (
      <p>
        Find out how our pioneer schools are helping us, what the Web App looks like so far, and what further
        activities, resources and rewards we are developing ready for launching SAMHE to a further 1000+ schools next
        year. Sign up to receive the SAMHE newsletter using the <Link to="/contact">Contact Form</Link>.
      </p>
    ),
  },
  {
    title: "SAMHE Newsletter #1",
    date: "October 2022",
    link: "https://0okur.mjt.lu/nl3/mQTX7l1mzcoLAT6c4bOTbA?m=AW4AAAE-dIQAAAABnkoAAAXQM3MAAAAAwmAAAMvxAB2PPwBjTmAFqd5NDEfrSouuNEhxrMXuGAAcHRA&b=1fefbeaf&e=6899577a&x=TOtXwFe9u4wE-1fl3ugZ6wxKTLKspYI6Zo-TAJSqnXE",
    text: (
      <>
        Read our first newsletter to find out what we're working on at the moment and how schools are helping with the
        project.
        <br /> Sign up to receive the SAMHE newsletter using the <Link to="/contact">Contact Form</Link>.
      </>
    ),
  },
];
